const termsOfService = [
  {
    title: "一、服務範圍",
    content: [
      "<ol><li>本功能用於幫助用戶（如學生、教師及管理者）在線管理、參與或排定補課活動，進行線上課程直播、課程影片上傳與管理攝影設備。</li><li>本功能僅適用於教育相關活動，不得用於任何非法或不當用途。</li></ol>",
    ],
  },
  {
    title: "二、帳戶與安全",
    content: [
      "<ol><li><strong>帳戶註冊</strong><br />使用本功能前，您需建立帳戶並提供準確、完整的資訊，包括姓名及聯絡方式。您應對所提供資訊的真實性負責。</li><li><strong>帳戶安全</strong><br />您應妥善保管帳戶登入憑據，並對所有通過您的帳戶進行的操作負責。如果發現任何未經授權的使用情況，請立即通知我們。</li>",
    ],
  },
  {
    title: "三、使用規範",
    content: [
      "<ol><li><strong>內容提交</strong><br />在使用本功能時，您提交的任何內容（如課程內容或錄製的課程）必須符合適用法律規範，不得包含誹謗、不雅、侵權或違法資訊。</li><li><strong>智慧財產權</strong><br />本功能內所有設計、軟體及內容（不包括用戶提交的內容）均為本公司或其授權方的財產，受到著作權法和其他法律的保護。未經本公司書面許可，不得複製、修改、散布或商業利用相關內容。</li><li><strong>禁止行為</strong></ol>",
      "<ul><li>使用本功能進行非法活動。</li><li>上傳含有病毒或惡意程式的檔案。</li><li>未經許可錄製或傳播他人的課程內容。</li></ul>",
    ],
  },
  {
    title: "四、隱私權",
    content: [
      "我們重視您的隱私，並依據 隱私權政策 處理您的個人資料。使用本功能表示您同意我們按照該政策處理相關資訊。",
    ],
  },
  {
    title: "五、責任限制",
    content: [
      "<ol><li>本功能可能因技術問題、維護或不可抗力暫停，對此本公司不承擔任何直接或間接責任。</li><li>本公司對於第三方服務提供者的內容或操作不負責，例如YouTube直播、影片上傳等功能的使用。</li><li>對於任何因用戶未遵守本條款而導致的損害或損失，本公司不承擔任何責任。</li></ol>",
    ],
  },
  {
    title: "六、費用與付款",
    content: [
      "<ol><li>本功能的基本功能為免費，但某些進階功能或附加服務可能收取費用。具體收費標準將另行公告或在服務頁面說明。</li><li>所有支付款項均不可退還，除非適用法律另有規定。</li></ol>",
    ],
  },
  {
    title: "七、終止與中止",
    content: [
      "<ol><li>若用戶違反本條款或適用法律，本公司有權隨時中止或終止其對本功能的使用權限。</li><li>您可隨時停止使用本功能，但終止使用不解除您於停止使用前所產生的義務。</li></ol>",
    ],
  },
  {
    title: "八、條款修改",
    content: [
      "本公司保留隨時修改本條款的權利，修改後將透過平台公告通知您。修訂版本自公告之日起生效，您繼續使用本功能即視為接受更新內容。",
    ],
  },
  {
    title: "九、聯絡我們",
    content: [
      "如對本條款有任何疑問或需進一步資訊，請聯繫：",
      "<ul><li><strong>公司名稱：</strong>克雷斯數位科技股份有限公司</li><li><strong>電子信箱：</strong>classup101@weclass.com.tw</li><li><strong>聯絡電話：</strong>+886-3-356-8371</li><li><strong>地址：</strong>桃園市桃園區同德二街3號5樓</li></ul>",
      "感謝您使用克雷斯數位科技的服務！我們期待為您提供最佳的補課與學習管理體驗。",
    ],
  },
  { content: ["<strong>更新日期：2025年1月7日<strong>"] },
  { content: ["Copyright ©Class Ltd. 2022"] },
];

export default termsOfService;
