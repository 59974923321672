const privacyData = [
  {
    title: "一、個人資料的蒐集",
    content: [
      "為提供您更好的服務，我們可能會蒐集以下類型的資料：",
      "<ol><li><strong>個人識別資訊：</strong>包括姓名、電子郵件地址、聯絡電話等，用於使用者登入、聯絡與身份識別。</li><li><strong>課程相關資料：</strong>包括課程排程資訊、錄製的課程影片及相關教材等，用於實現補課功能。</li><li><strong>系統使用數據：</strong>例如登入時間、功能使用情況等，以提升服務效能與用戶體驗。</li></ol>",
    ],
  },
  {
    title: "二、資料的使用方式",
    content: [
      "我們將您的資料用於以下用途：",
      "<ol><li><strong>提供補課服務：</strong>協助您參與、管理及記錄補課活動。</li><li><strong>通知與提醒：</strong>針對課程安排以系統推播通知提供提醒。</li><strong><li>優化功能：</strong>根據匿名化的使用行為數據，改進產品與服務品質。</li><li><strong>法律規範要求：</strong>依法律或政府機關要求提供相關資訊。</li>",
      "我們不會出售、租借或以其他方式與第三方共享您的個人資料，除非經您同意或法律允許。",
    ],
  },
  {
    title: "三、第三方服務",
    content: [
      "為提供更好的服務，我們可能會使用以下第三方服務：",
      "<strong>1. YouTube 服務：</strong>",
      "<ul><li>用於課程錄影的影片上傳，提供即時直播功能，以便學生觀看課程進行中的直播或補課紀錄。</li><li>您的影片內容僅供授權使用者觀看，我們不會對您的影片進行公開分享，除非經您同意或依法律規定。</li></ul>",
      "這些服務由相關提供商獨立運營，其數據處理受其隱私政策約束（如 Google隱私權政策）。",
    ],
  },
  {
    title: "四、資料的保護與儲存",
    content: [
      "本公司採取合理的措施來保護您的資料安全，包括：",
      "<ol><li><strong>數據加密：</strong>我們對傳輸與儲存中的資料進行加密處理，避免未授權存取。</li><li><strong>存取控制：</strong>只有經授權的人員能夠接觸您的資料。</li><strong><li>資料保存期限：</strong>除非法律另有規定，我們僅保留您的個人資料至補課服務停止或關閉帳戶為止。</li></ol>",
    ],
  },
  {
    title: "五、您的權利",
    content: [
      "您擁有以下權利，且可隨時與我們聯絡執行：",
      "<ol><li>查詢或要求複本：請求檢視我們持有的個人資料。</li><li>更正或刪除：更新您的資訊或要求刪除相關資料。</li><li>撤回授權：針對特定權限或功能，撤回先前同意的操作（可能影響部分服務使用）。</li></ol>",
    ],
  },
  {
    title: "六、聯絡我們",
    content: [
      "若您對本隱私權政策有疑問或需要進一步協助，請聯絡我們：",
      "<ul><li><strong>公司名稱：</strong>克雷斯數位科技股份有限公司</li><li><strong>電子信箱：</strong>classup101@weclass.com.tw</li><li><strong>聯絡電話：</strong>+886-3-356-8371</li><li><strong>地址：</strong>桃園市桃園區同德二街3號5樓</li></ul>",
    ],
  },
  {
    title: "七、政策更新",
    content: [
      "本隱私權政策可能不定期更新。任何重大變更將透過平台公告，修訂後的政策將即時生效。<br />感謝您信任克雷斯數位科技，使用我們的班級管理系統與線上補課功能！",
    ],
  },
  { content: ["<strong>更新日期：2025年1月7日<strong>"] },
  { content: ["Copyright ©Class Ltd. 2022"] },
];

export default privacyData;
