import "./styles/GenericPolicy.css";
import privacyData from "./PrivacyData";
import termsOfService from "./TermsOfService";
import { useState, useEffect } from "react";
import { Fade } from "react-reveal";

const GenericPolicy = ({ type }) => {
  const [image, setImage] = useState("/assets/privacyPolicy01.png");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1028) {
        setImage(
          type === "privacy"
            ? "/assets/privacyPolicy01.png"
            : "/assets/termsOfService01.png"
        );
      }
      if (width <= 1028) {
        setImage(
          type === "privacy"
            ? "/assets/privacyPolicy02.png"
            : "/assets/termsOfService02.png"
        );
      }
      if (width <= 640) {
        setImage(
          type === "privacy"
            ? "/assets/privacyPolicy03.png"
            : "/assets/termsOfService03.png"
        );
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [type]);

  return (
    <div className="class-genericPolicy mt-header">
      <Fade>
        <div className="class-container">
          <img src={image} alt="" />
          <h1 className="class-title">
            {type === "privacy" ? "隱私權政策" : "服務條款"}
          </h1>
        </div>
      </Fade>
      <div className="class-content">
        <p className="class-subTitle">
          {type === "privacy"
            ? "克雷斯數位科技（以下簡稱「本公司」）致力於保護使用者的個人資料與隱私權。本隱私權政策說明班級管理系統中的線上補課功能（以下簡稱「本功能」）如何收集、使用及保護您的個人資訊。使用本功能即表示您已同意本政策中的內容。"
            : "歡迎使用克雷斯數位科技（以下簡稱「本公司」）所提供的班級管理系統線上補課功能（以下簡稱「本功能」）。在使用本功能前，請仔細閱讀以下條款。使用本功能即表示您已閱讀、了解並同意遵守這些條款。"}
        </p>
        {type === "privacy"
          ? privacyData.map((group, index) => (
              <div className="class-group" key={index}>
                <h2>{group.title}</h2>
                <div className="class-list">
                  {group.content.map((item, idx) => (
                    <div key={idx} dangerouslySetInnerHTML={{ __html: item }} />
                  ))}
                </div>
              </div>
            ))
          : termsOfService.map((group, index) => (
              <div className="class-group" key={index}>
                <h2>{group.title}</h2>
                <div className="class-list">
                  {group.content.map((item, idx) => (
                    <div key={idx} dangerouslySetInnerHTML={{ __html: item }} />
                  ))}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default GenericPolicy;
